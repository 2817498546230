<!--
 * @Author: your name
 * @Date: 2021-11-30 10:40:33
 * @LastEditTime: 2022-01-05 13:49:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Waybill/_details/index.vue
-->
<template>
  <div>
    <TabHeader title="预报详情"></TabHeader>
    <div class="fd-outer">
      <a-row>
        <a-col :span="8">
          <span class="fd-s1">预报单号：{{ form.forecast_no }}</span>
        </a-col>
        <a-col :span="8">
          <span class="fd-s2">
            <span class="s1">计划类型：</span>
            <span class="s2" style="color: #ff0000" v-if="form.type == 2"
              >未建计划</span
            >
            <span class="s2" v-if="form.type == 1">计划</span>
          </span>
        </a-col>
      </a-row>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">预报信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">预报时间：</span
              ><span class="s2">{{ form.created_at | timeStr }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">货件数量：</span
              ><span class="s2">{{ form.box_count }}件</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">已发货数量：</span
              ><span class="s2">{{ form.use_box_count }}件</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">运单数：</span
              ><span class="s2">{{ form.waybill_count }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">货好时间：</span
              ><span class="s2">{{ form.good_time | timeStr }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">重量：</span
              ><span class="s2">{{ form.weight }}kg</span></span
            >
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">体积：</span
              ><span class="s2">{{ form.volume }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">收货地址：</span
              ><span class="s2">{{ form.address }}</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line" v-if="form.receive_type == 1"></div>
      <div class="fd-inner" v-if="form.receive_type == 1">
        <div class="fd-inner-title">司机信息</div>
        <a-row style="margin-top: 20px">
          <a-col :span="8">
            <span class="fd-s2" v-if="form.other_driver.name"
              ><span class="s1">姓名：</span
              ><span class="s2">{{ form.other_driver.name }}</span></span
            >
            <span class="fd-s2" v-else-if="form.driver.name"
              ><span class="s1">姓名：</span
              ><span class="s2">{{ form.driver.name }}</span></span
            >
            <span class="fd-s2" v-else
              ><span class="s1">姓名：</span
              ><span class="s2">司机分配中</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2" v-if="form.other_driver.phone"
              ><span class="s1">手机号：</span
              ><span class="s2">{{ form.other_driver.phone }}</span></span
            >
            <span class="fd-s2" v-else-if="form.driver.phone"
              ><span class="s1">手机号：</span
              ><span class="s2">{{ form.driver.phone }}</span></span
            >
            <span class="fd-s2" v-else
              ><span class="s1">手机号：</span><span class="s2">-</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">运单</div>
        <a-row
          style="margin-top: 20px"
          v-for="(item, index) in form.waybills"
          :key="index"
        >
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">运单编号：</span
              ><span class="s2">{{ item.waybill_no }}</span></span
            >
          </a-col>
          <a-col :span="8">
            <span class="fd-s2"
              ><span class="s1">件数：</span
              ><span class="s2">{{ item.cargoes_num }}</span></span
            >
          </a-col>
        </a-row>
      </div>
      <div class="fd-line"></div>
      <div class="fd-inner">
        <div class="fd-inner-title">照片</div>
        <a-row style="margin-top: 20px">
          <a-col :span="6">
            <img :src="form.photos" class="fd-img1" />
          </a-col>
        </a-row>
      </div>
      <div style="margin-top: 20px">
        <a-button type="primary" @click="goBack"> 返回 </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { forecastInfo } from "@/api/Waybill";
import moment from "moment";
// import imgUrl from "@/utils/imgUrl"
export default {
  name: "Waybill_Forecast_details",
  components: {},
  data() {
    return {
      rowId: "",
      limit: 1,
      confirmLoading: false,

      form: {
        forecast_no: "",
        type: "",
        created_at: "",
        box_count: "",
        use_box_count: "",
        waybill_count: "",
        good_time: "",
        weight: "",
        address: "",
        volume: "",
        photos: "",
        driver: {
          name: "",
          phone: "",
        },
        other_driver: {
          name: "",
          phone: "",
        },
        waybills: [],
      },
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      let id = this.$route.query.id;
      if (id) {
        this.rowId = id;
        forecastInfo({ forecastId: id * 1 }).then((res) => {
          this.form = { ...res.data };
          if (this.form.photos) {
            if (!this.form.photos.includes("http")) {
              this.form.photos = process.env.VUE_APP_CDN + this.form.photos;
            }
          }
          // this.form.photos = process.env.VUE_APP_CDN + this.form.photos;
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  filters: {
    timeStr(val) {
      let str = "";
      if (val && val > 0) {
        let times = val * 1000;
        str = moment(times).format("YYYY-MM-DD HH:mm:ss");
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.fd-outer {
  padding: 20px 30px;
}
.fd-s1 {
  font-size: 20px;
  color: #fb4e0c;
}
.fd-s2 {
  .s1 {
    font-size: 14px;
    color: #5c5c5c;
  }
  .s2 {
    font-size: 14px;
    color: #9a9a9a;
  }
}
.fd-inner {
  margin-top: 20px;
  .fd-inner-title {
    font-size: 16px;
    font-weight: 600;
  }
  .fd-img1 {
    width: 180px;
    height: 167px;
  }
}
</style>
